<template>
  <div class="home">

    <van-nav-bar title="写日志" left-arrow id="reset">
      <template #right>
        <van-icon name="plus" color="#ffffff" />
      </template>
    </van-nav-bar>
    <div class="header">
    <van-field
	id="message"
  v-model="message"
  rows="1"
  autosize
  type="textarea"
  placeholder=""
/>
    </div>
<!--    <div class="footer">
<van-uploader    class="fimg"/>
      <!-- <img src="../static/写日志/xiangji.png" class="fimg" alt="" width="64" height="64">
    </div> -->
      <van-button type="info"  @click=bohao() >保&nbsp;&nbsp;&nbsp;&nbsp;存</van-button>

  </div>
</template>


<script>
	import { Message } from 'iview'
export default {
  data() {
    return {
        value:"",
		id:'',
		message:'',
		systemDate:'',
      numList: [],
    };
  },created () {
	  this.addDate()
	  console.log(this.systemDate);
  },
  methods: {
	  // this.$route.query.id
	  addDate() {
		  var nowDate = new Date();
		  var date = {
		  year: nowDate.getFullYear(),
		  month: nowDate.getMonth() + 1,
		  day: nowDate.getDate(),
		  }
		  var systemDate = date.year + '-' + (date.month >= 10 ? date.month :'0' + date.month) + '-' + (date.day >= 10 ? date.day : '0' + date.day)
		  this.systemDate = systemDate;
	  },
	  
	  bohao(){
		  if(this.message){
			 
			 console.log(this.value);
			 	let param = new URLSearchParams();
			 	
			 	param.append("category_id", 4);
			 	param.append("content", this.message);
			 	param.append("subject", this.systemDate+' 工作日志');
			 	 param.append("token", this.$store.getters. getDemoValue);
			 	
			 	this.axios.post('/vue.php?m=log&a=mylog_add',param).then(res => {
			 		if (res.data.status === -1) {
			 
			 			setTimeout(()=>{
			 				this.$router.push({
			 				  path:"/"
			 				})
			 			},1000)
			 		}
			 		if (res.data.status === 1) {
			 			Message.info('提交成功');
						
						setTimeout(()=>{
							this.$router.push({
							  path:"/bangong"
							})
						},1000)
			 		
			 		}
			 		console.log(res);
			 	}).catch(function (error){
			 		console.log(error);
			 	}); 
		  }else{
			  Message.info('请输入内容');
		  }
	  },
      end(e){
          this.value  += e
      },
      del(){
          let str = this.value
        //   this.value =this.value(0,this.value.length-1)
        this.value = str.slice(0,str.length-1)
      }
  },
};
</script>

<style lang="scss" scoped>
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
.fimg {
  margin: 15px;
}
.home{
  background: #f2f2f2;
  padding-bottom: 80px;

}
.header {
  height: 200px;
  padding: 20px;
  background: #ffffff;
  p {
    word-wrap: break-word;
    word-break: break-all;
  }
}
.footer {
  height: 200px;
  background: #f2f2f2;

}
  button{
      width: 80%;
      display: block;
      margin: 0 auto;
  }
</style>